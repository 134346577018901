<template>
  <div class="index-container">
    <div class="main">
      <div class="banner-box">
        <div class="banner-text">{{ $t("message.index.bannerText1") }}</div>
        <div class="banner-text">{{ $t("message.index.bannerText2") }}</div>
        <img src="../../../assets/images/banner.png" class="banner-img" alt="" />
      </div>
      <!-- select -->
      <div class="content" id="start">
        <div class="title">{{ $t("message.index.contentTitle") }}</div>
        <div class="select-box">
          <select class="select_list" @change="handleCountryChange" v-model="curCountryName">
            <option v-for="(item, index) in countryList" :key="'country' + index" :value="item">
              {{ item }}
            </option>
          </select>
          <div class="down_arrow"></div>
        </div>
        <div class="select-box">
          <select class="select_list" @change="handleDocumentChange" v-model="curDocumentInfo">
            <option v-for="(item, index) in allSpecs[curCountryName]" :key="'document' + index" :value="item">
              {{ item.document_type }}
            </option>
          </select>
          <div class="down_arrow"></div>
        </div>

        <div class="price">
          {{ $t("message.index.downloadText") }} <span class="old-price">$3.99</span>
          <span class="real-price">$1.99</span>
          <span class="off-price"> ({{ $t("message.index.priceOff") }})</span>
        </div>
        <!-- get now btn -->
        <div class="get-btn" @click="handleGoStartPage(1)">
          {{ $t("message.index.getNow") }}
        </div>
        <!-- desc -->
        <div class="desc-box">
          <div class="desc-list" v-for="(item, index) in descList" :key="index">
            <div class="desc-check"></div>
            <div>{{ item }}</div>
          </div>
        </div>
      </div>
      <!-- product -->
      <div class="product-box">
        <div class="product-box-title">{{ $t("message.index.productTitle") }}</div>
        <div class="product-list">
          <img src="../../../assets/images/card1.svg" class="card-list" alt="" />
          <div class="product-desc">{{ $t("message.index.productText1") }}</div>
        </div>
        <div class="product-list">
          <img src="../../../assets/images/card2.svg" class="card-list" alt="" />
          <div class="product-desc">{{ $t("message.index.productText2") }}</div>
        </div>
        <div class="product-list">
          <img src="../../../assets/images/card3.svg" class="card-list" alt="" />
          <div class="product-desc">{{ $t("message.index.productText3") }}</div>
        </div>
      </div>
      <!-- download -->
      <div class="download-box" id="Help">
        <div class="download-box-title">{{ $t("message.index.downloadText2") }}</div>
        <img src="../../../assets/images/download.svg" class="download-svg" alt="" />
      </div>
    </div>
    <div class="fix-btn">
      <div class="btn-box" @click="handleGoStartPage(2)">
        <div class="get-btn">{{ $t("message.index.getNow") }}</div>
        <div class="btn-desc">
          {{ $t("message.index.downloadText") }}(<span class="old-price">$3.99</span>)<span class="real-price"
            >$1.99</span
          >
        </div>
        <div class="off-price">{{ $t("message.index.priceOff") }}</div>
      </div>
    </div>
    <Footer></Footer>

  </div>
</template>

<script>
import { getCountries } from "@/api/common.js";
import { reportWebVitals } from "@/utils/ga.js";
import { mapState, mapMutations } from "vuex";
import Footer from "@/components/Footer.vue";

export default {
  name: "index",

  data() {
    return {
      countryList: [],
      curCountryName: "",
      documentList: {},
      curDocumentInfo: {},
      descList: [],
      allSpecs: {},

      search: "",
      ifExp: false,
      variant: 0,

      id: null, //国家id
    };
  },
    components: {
    Footer,
  },
  computed: mapState([
    // 映射 this.count 为 store.state.count
    "documentInfo",
  ]),
  mounted() {
    const locale = this.$i18n.locale;
    const messages = this.$i18n.messages;
    const desc = messages[locale].message.index.desc;
    this.descList = desc.split("/");
    this.id = this.$route.query.id;
    this.getCountries();
  },
  methods: {
    ...mapMutations([
      "setDocumentInfo", // 将 `this.setDocumentInfo()` 映射为 `this.$store.commit('setDocumentInfo')`
    ]),
    handleCountryChange() {
      if (!this.allSpecs[this.curCountryName]) {
        return;
      }
      this.curDocumentInfo = this.allSpecs[this.curCountryName][0];
      // 切换国家打点
      reportWebVitals("country_select_index", 1, "spec", "", {
        countryName: this.curCountryName,
      });
    },
    handleDocumentChange() {
      // 切换类型打点
      reportWebVitals("photo_type_select_index", 1, "spec", "", {
        photoId: this.curDocumentInfo.id,
      });
    },
    handleGoStartPage(type) {
      if (type == 1) {
        reportWebVitals("middle_getnow_click_index", "", "getnow", "core");
      } else {
        reportWebVitals("bottom_getnow_click_index", "", "getnow", "core");
      }
      this.setDocumentInfo(this.curDocumentInfo);

      this.$router.push({ name: "start",query:{id: this.$route.query.id} });
    },
    getCountries() {
      getCountries()
        .then((res) => {
          if (res?.body?.code == 0) {
            const data = res.body.data;
            this.allSpecs = data?.specs?.specs || {};
            this.countryList = data?.specs?.countries || [];
            // url 中自带国家id
            if (this.id) {
              // 根据id 获取国家和类型信息
              for (let k in this.allSpecs) {
                const item = this.allSpecs[k];
                for (let i = 0; i < item.length; i++) {
                  if (item[i].id == this.id) {
                    this.curCountryName = k;
                    this.curDocumentInfo = this.allSpecs[this.curCountryName][i];
                    break;
                  }
                }
              }
            } else if (this.documentInfo) {
              this.curCountryName = this.documentInfo.country;
              this.curDocumentInfo = this.documentInfo;
            } else {
              this.curCountryName = (this.countryList && this.countryList[0]) || "";
              this.documentList = this.allSpecs[this.curCountryName];
              this.curDocumentInfo = (this.documentList && this.documentList[0]) || "";
            }
            reportWebVitals("spec_success", 1, "spec", "", {
              code: res.body.code,
            });
          } else {
            reportWebVitals("spec_fail", 1, "spec", "", {
              code: res.body.code,
            });
          }
        })
        .catch((err) => {
          reportWebVitals("spec_fail", 1, "spec", "", { code: "-1" });
          console.error(err);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.index-container {
  padding-bottom: 200px;
  .main {
    .banner-box {
      width: 100%;
      background: #ffbf00;
      padding-top: 50px;
      padding-bottom: 40px;
      .banner-text {
        font-size: 40px;
        font-weight: 700;
        color: #242a33;
        text-align: center;
        // margin-bottom: 14px;
      }
      .banner-img {
        width: 650px;
        height: auto;
        margin: 0px auto 0;
        padding-left: 90px;
      }
    }
    .content {
      padding: 50px 0;
      .title {
        font-size: 40px;
        color: #242a33;
        text-align: center;
        font-weight: 700;
        margin-bottom: 30px;
      }

      .price {
        font-size: 30px;
        color: #242a33;
        text-align: center;
        font-weight: 400;
        margin-top: 40px;
        .old-price {
          color: #cc210f;
           text-decoration: line-through;
        }
        .real-price {
          font-size: 50px;
          font-weight: 700;
        }
        .off-price {
          color: #15993d;
        }
      }
      .get-btn {
        width: 360px;
        height: 80px;
        line-height: 80px;
        background: #ffbf00;
        border-radius: 6px;
        margin: 30px auto 0;
        text-align: center;
        font-size: 30px;
        color: #242a33;
        font-weight: 600;
      }
      .desc-box {
        margin-top: 50px;
        padding-left: 50px;
        width: 90%;
        .desc-list {
          font-size: 28px;
          display: flex;
          color: #242a33;
          line-height: 30px;
          font-weight: 400;
          margin-bottom: 20px;
          line-height: 32px;
          .desc-check {
            width: 8px;
            height: 12px;
            border-color: #44c562;
            border-style: solid;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            margin-right: 14px;
            margin-top: 8px;
          }
        }
      }
    }
    .product-box {
      background: #ffbf00;
      padding: 50px 0;
      .product-box-title {
        font-size: 40px;
        color: #242a33;
        text-align: center;
        font-weight: 700;
        margin-bottom: 60px;
      }
      .product-list {
        margin-bottom: 60px;
        .card-list {
          width: 390px;
          height: auto;
          margin: 0 auto;
        }
        .product-desc {
          width: 300px;
          margin: 30px auto 0;
          font-size: 40px;
          color: #242a33;
          font-weight: 400;
          text-align: center;
        }
      }
    }
    .download-box {
      padding: 50px 0;
      .download-box-title {
        width: 600px;
        font-size: 40px;
        color: #242a33;
        text-align: center;
        font-weight: 700;
        margin: 0 auto 60px;
      }
      .download-svg {
        width: 200px;
        height: auto;
        margin: 0 auto;
      }
    }
  }
  .fix-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    background: #fff;
    color: #242a33;
    .btn-box {
      width: 85%;
      height: 90px;
      margin: 0 auto;
      background: #ffbf00;
      border-radius: 10px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      .get-btn {
        font-size: 30px;
        color: #242a33;
        font-weight: 600;
      }
      .btn-desc {
        .real-price {
          font-weight: bolder;
        }
      }
      .off-price {
        width: 120px;
        height: 40px;
        position: absolute;
        top: 0;
        right: 0;
        background: #15993d;
        border-radius: 0px 8px 0px 8px;
        font-size: 24px;
        color: #ffffff;
        font-weight: 400;
        text-align: center;
      }
    }
  }
}

@media only screen and (device-width: 375px) and (min-device-height: 812px) and (-webkit-device-pixel-ratio: 3),
  /* iphone xr 2436x1125px at 375ppi */ screen and (device-width: 375px) and (min-device-height: 812px) and (-webkit-device-pixel-ratio: 2),
  /* iphone xr 2436x1125px at 458ppi & ip11*/ screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2),
  /* iphone xs 2436x1125px at 458ppi & ipx & ip11Pro */ screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3),
  /* iphone xs max 2688x1242px at 458ppi & ip11 pro max*/ screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3),
  /* ip12 pro max*/ screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3),
  /* ip12 */ screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .fix-btn {
    padding-bottom:34px;
    box-sizing: content-box;
  }
}
</style>
